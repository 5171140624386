import { DatePipe } from '@angular/common';
import { Component, HostListener, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AiToolsApiFacade } from 'src/app/Facade/ApiFacade/ai-tools.facade';
import { IAiToolComment } from 'src/app/Shared/Models/ai-tools.interface';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { AiToolsCommentaryService } from '../ai-tools-commentary.service';

@Component({
  selector: 'app-ai-tools-comment-item',
  templateUrl: './ai-tools-comment-item.component.html',
  styleUrls: ['./ai-tools-comment-item.component.scss']
})
export class AiToolsCommentItemComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    if(event.key == "Escape"){
      this.onEditCancel()
    }
  }

  @Input() parentId;
  @Input() toolId;
  currentCommentIdReply;
  currentCommentIdEdit;

  commentAnswerControl = new FormControl("");
  commentEditControl = new FormControl("");
  commentToDelete:IAiToolComment;
  commentToEdit:IAiToolComment;
  aiToolComments:IAiToolComment[];
  allComments:IAiToolComment[];
  userEmail:string;
  isParentDeleted:boolean = false;
  isMobile: boolean = false;

  modalRef?: BsModalRef;

  datepipe: DatePipe = new DatePipe('en-US')
  today = this.datepipe.transform(Date.now(),'yyyy-MM-dd')?.split('T')[0]


  userPhotoSettings = {
    userPhotoStyles: {
      'width':'64px',
      'height':'64px'
    },
    size: 'middle'
  }

  constructor(private aitoolsApiFacade:AiToolsApiFacade,
    private aitoolCommentaryService:AiToolsCommentaryService,
    private modalService: BsModalService,
    private userProfileService:UserProfileService) { }

  ngOnInit(): void {
    this.aitoolsApiFacade.currentAiToolCommentsSource$.subscribe(source => {
      var coment =  source.find(c => c.toolId == this.toolId)
      if(coment){
        this.aiToolComments = coment.comments.filter(comment=> comment.parentId == this.parentId);
        this.allComments =  coment.comments;
        this.isParentDeleted = coment.comments.find(c => c.id == this.parentId && c.deleted == true) != undefined;
      }
    })

    this.userProfileService.userProfileSource$.subscribe(user => {
      this.userEmail = user.userPrincipalName;
    })

    this.aitoolCommentaryService.commentarySource$.subscribe(state => {
      this.currentCommentIdReply = state.currentCommentIdReply;
      this.currentCommentIdEdit = state.currentCommentIdEdit;
    })

    this.onResize()
  }

  onDeleteComment(){
    this.aitoolsApiFacade.deleteComment(this.commentToDelete);
    this.modalRef?.hide();
  }

  onReplyStart(comment){
    this.commentAnswerControl = new FormControl("")
    this.aitoolCommentaryService.setCurrentCommentaryReplyId(comment.id);
  }

  onConfirmReply(comme:IAiToolComment){
    let comment ={
      "toolId": this.toolId,
      "parentId": comme.id,
      "topParentId": comme.topParentId,
      "comment": this.commentAnswerControl.value,
      "relatedUserEmail": comme.userEmail
    }

    if(this.commentAnswerControl.value.trim() != '' && this.commentAnswerControl.value != ''){
      this.aitoolsApiFacade.addAiToolComment(comment);
      this.aitoolCommentaryService.setCurrentCommentaryReplyId(-0);
    }
  }

  onEditConfirm(){
    let comme ={
      "commentId": this.commentToEdit.id,
      "comment": this.commentEditControl.value,
      "toolId":this.toolId
    }

    if(this.commentEditControl.value.trim() != '' && this.commentEditControl.value != ''){
      this.aitoolsApiFacade.editAiToolComment(comme);
      this.aitoolCommentaryService.setCurrentCommentaryEditId(-1);
    }

  }

  onEditStart(comment){
    this.commentToEdit = comment;
      this.commentEditControl = new FormControl(comment.comment);
      this.aitoolCommentaryService.setCurrentCommentaryEditId(comment.id);
  }

  onEditCancel(){
    this.commentEditControl = new FormControl("");
    this.aitoolCommentaryService.setCurrentCommentaryEditId(-1);
  }

  AddCommentLike(comment: IAiToolComment){
    this.aitoolsApiFacade.addAiToolCommentLike(comment.id, comment.toolId, comment.userEmail).subscribe( result => {
      if(result == true){
        comment.isUserLike = true;
        comment.likesCount +=1;
      }
    })
  }

  RemoveCommentLike(comment: IAiToolComment){
    this.aitoolsApiFacade.removeAiToolCommentLike(comment.id).subscribe( result => {
      if(result == true){
        comment.isUserLike = false;
        comment.likesCount -=1;
      }
    })
  }

  hasChildrem(commentId){
    return this.allComments.find(c => c.parentId == commentId) != undefined;
  }


  openAlertModal(template: TemplateRef<any>, comment) {
    this.commentToDelete = comment;
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768;
  }
}
