<div (click)="closeHeaderModalEmitter()" class="profile-container" *ngIf="userBasicInfo" [ngClass]="{'dashboard-opened': isDashboardOpened}">

  <div class="profile-menu">

    <a (click)="closeDashboardPlease()" class="playtispace-logo-link" routerLink="/">
      <div class="playtispace-logo"></div>
    </a>
    <!--external employee -->
    <a *ngIf="!displaySelfyModule || displaySelfyModule == null" class="profile" [routerLink]="['/personal-area/profile']" [routerLinkActive]="['menu-item--active']"
      [routerLinkActiveOptions]="{ exact: true }">
      <div class="profile__photo">
        <img *ngIf="userBasicInfo.birthday && userBasicInfo.birthday == today" class="profile__photo-birthday" src="/assets/images/global/birthday.png" />
        <img [style]="userBasicInfo.photo ? {border: '3px solid #fff'}: {border: '3px solid rgba(255, 255, 255, 0)'}"
          [src]="userBasicInfo.photo || '/assets/images/global/no-avatar.png'" alt="profile-photo" />
      </div>
      <div class="profile__text">
        <!-- <div class="profile__new">
          New
        </div> -->
        <div class="profile__name">
          Hi, {{ userBasicInfo.givenName }}
        </div>
        <div class="profile__post">
          My profile
        </div>
      </div>
    </a>
    <!--playtica employee -->
    <div *ngIf="displaySelfyModule" (click)="openModalEmiter.emit()" class="profile">
    <div class="profile__photo">
      <img *ngIf="userBasicInfo.birthday && userBasicInfo.birthday == today" class="profile__photo-birthday" src="/assets/images/global/birthday.png" />
      <img [style]="userBasicInfo.photo ? {border: '3px solid #fff'}: {border: '3px solid rgba(255, 255, 255, 0)'}"
        [src]="userBasicInfo.photo || '/assets/images/global/no-avatar.png'" alt="profile-photo" />
    </div>
    <div class="profile__text">
      <div class="profile__new">
        New
      </div>
      <div class="profile__name">
        Hi, {{ userBasicInfo.givenName }}
      </div>
      <div class="profile__post">
        Selfy Zone
      </div>
    </div>
  </div>
    <app-global-nav-menu
      [mainMenu]="mainMenu"
      [tasksCountToSee]="tasksCountToSee"
      [isDashboardOpened]="isDashboardOpened"
    ></app-global-nav-menu>

    <!-- <app-wiki-nav-menu></app-wiki-nav-menu> -->

  </div>

  <div class="profile-divider"></div>

  <app-my-apps [apps]="favoriteApps"></app-my-apps>

  <div *ngIf="banner" class="banner">
    <a href="{{banner.link}}">
       <img src="{{banner.image}}" alt="">
    </a>
  </div>

  <div class="edit-mode">
    <button class="edit-mode__button on" *ngIf="!isEditModeActive && (isMainSiteAdmin == true || isWikiSiteAdmin == true)"
      (click)="isEditMenuModeChange(true)">
      <img src="/assets/images/global/edit-app-pencil.png" />
      Turn on edit mode
    </button>
    <button class="edit-mode__button off" *ngIf="isEditModeActive  && (isMainSiteAdmin == true || isWikiSiteAdmin == true)"
      (click)="isEditMenuModeChange(false)">
      <img src="/assets/images/global/edit-app-remove.png" />
      Turn off edit mode
    </button>
  </div>
</div>
