<div class="header-wrapper" [ngClass]="{'hide-search-part header-wrapper-pt': wellbeingHeader}" >

  <header [ngClass]="{'header-transparent': headerTransparent, 'header-gap':wellbeingHeader}" class="header" #header>
    <app-search-header style="flex-basis: 100%;"></app-search-header>
    <!-- <div [ngClass]="{'hide-header-part': wellbeingHeader}" class="header__date"> -->
    <div class="header__date">
      {{currentDate | date:'EEEE, MMMM d'}}
    </div>
    <div class="header__logo-box">
        <div class="header__logo-image">
          <button *ngIf="!showTooltip" class="header__logo-image__button" type="button"
          (click)="toggleHeaderModal(templateHeader)"
          >
            <img class="bounce" src="../../../../assets/images/global/logo-icon.png" alt="logo">
          </button>
          <button  class="header__logo-image__button" type="button"
          (click)="toggleHeaderModal(templateHeader)"
          [tooltip]="headerTooltipTemplate"
          containerClass="headerTooltip"
          #pop="bs-tooltip"
          container="body"
          [adaptivePosition]="false"
          triggers=""
          placement="bottom"
          >
            <img *ngIf="showTooltip" class="bounce" src="../../../../assets/images/global/logo-icon.png" alt="logo">

          </button>
          <button  #showHeaderTooltipTemplate (click)="pop.show()">  </button>
          <ng-template  class="headerTooltipTemplate" #headerTooltipTemplate>
            <div>Click for the menu</div>
            <div ><button (click)="hideTooltip()"><img src="../../../../assets/images/global/icon-system-cross-medium-gray.png" ></button></div>
          </ng-template>
        </div>

    </div>

  </header>
</div>

<ng-template #templateHeader>
  <app-header-modal
  (closeModalEmiter)="closeHeaderModal()"
  ></app-header-modal>
</ng-template>
<app-mobile-menu [mainMenu]="mainMenu"></app-mobile-menu>

