import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';
import { AnnouncementsGraphFacade } from 'src/app/Facade/GraphApiFacade/announcements-graph.facade';
import { MainMenu } from '../../../Shared/Models/mainMenu.model';
import { DashboardService } from '../../Services/DashboardService/DashboardService';

@Component({
  selector: 'app-global-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {

  @Input() mainMenu: MainMenu[];
  @Input() isMobile: boolean = false;
  @Input() tasksCountToSee: number =0;
  @Input() isDashboardOpened: boolean;

  notificationsCountToSee: number =0;
  birthdayCount: number = 0;

  newannouncementsCountSourceSubscription:Subscription;
  constructor(private announcementsGraphFacade:AnnouncementsGraphFacade, private userApiFacade:UserApiFacade,private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.getCountNotifications()
     this.newannouncementsCountSourceSubscription = this.announcementsGraphFacade.newannouncementsCountSource$.subscribe(source => {
      this.notificationsCountToSee = source;
    });

    this.userApiFacade.countUserBirthdayWishes$.subscribe(count => {
      this.birthdayCount = count;
    })

  }

  getCountNotifications(){
    this.announcementsGraphFacade.getUserCountNotificationsToSee();
  }

  ngOnDestroy(): void {
    this.newannouncementsCountSourceSubscription.unsubscribe();
  }
  closeDashboardPlease(){
    this.dashboardService.close();
  }
}
