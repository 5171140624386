import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AiToolsApiFacade } from 'src/app/Facade/ApiFacade/ai-tools.facade';
import { IAiArticleComment, IAiArticleDetails } from 'src/app/Shared/Models/ai-tools.interface';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { AiArticlesCommentaryService } from './ai-articles-commentary.service';

@Component({
  selector: 'app-ai-articles-comments',
  templateUrl: './ai-articles-comments.component.html',
  styleUrls: ['./ai-articles-comments.component.scss']
})
export class AiArticlesCommentsComponent implements OnInit {

  @Input() articleId;
  @Input() padding;
  @Input() isInsideOfModal = false;

  isEditing = false;
  isAnswer = false;
  isMobile = false;
  loadedAllComments = false;
  showAllcomments = false;
  showLessComments = false;
  loadMoreButtonClicked = false;
  addedComments = false;
  currentCommentId;
  currentCommentIdReply;
  currentCommentIdEdit;
  articleComments:IAiArticleComment[];

  commentToDelete:IAiArticleComment;
  commentToEdit:IAiArticleComment;
  commentControl = new FormControl("");
  commentEditControl = new FormControl("");
  commentReplyControl = new FormControl("");
  userEmail:string;
  aiArticleDetails:IAiArticleDetails;
  modalRef?: BsModalRef;
  mobileModalRef?: BsModalRef;
  limitSlice = 2;
  datepipe: DatePipe = new DatePipe('en-US')
  today = this.datepipe.transform(Date.now(),'yyyy-MM-dd')?.split('T')[0]

  userPhotoSettings = {
    userPhotoStyles: {
      'width':'38px',
      'height':'38px'
    },
    size: 'smaller'
  }

  constructor(private aiToolsApiFacade:AiToolsApiFacade,
    private aiArticlesCommentaryService:AiArticlesCommentaryService,
    private modalService: BsModalService,
    private userProfileService:UserProfileService) { }

  ngOnInit(): void {
    this.aiToolsApiFacade.currentAiArticleCommentsSource$.subscribe(source => {
      let comments = source.find(c => c.articleId == this.articleId)

      if(comments != undefined){
        this.articleComments = comments.comments.filter(comment=> comment.parentId == null);
        console.log('comments', comments)
        this.checkLoadMore(this.articleComments)
      }

      if(comments && comments.comments.length > 0 && this.loadMoreButtonClicked){
        this.limitSlice = this.articleComments.length;
        this.loadedAllComments = true;
        this.showLessComments =  true;
      }
    })

    this.aiToolsApiFacade.aiArticleDetailsSource$.subscribe(source => {
      let details = source.find(s => s.articleId == Number(this.articleId))
      if(details != undefined){
        console.log('details', details)
        this.aiArticleDetails = details;
        this.checkLoadMore(this.articleComments)
      }
    })

    this.userProfileService.userProfileSource$.subscribe(user => {
      this.userEmail = user.userPrincipalName;
    })

    this.aiArticlesCommentaryService.commentarySource$.subscribe(state => {
      this.currentCommentIdReply = state.currentCommentIdReply;
      this.currentCommentIdEdit = state.currentCommentIdEdit;

      if(this.articleId != state.currentCommentLoadMore){
        this.onViewLessComments();
      }
    })

    if (this.isInsideOfModal) {
      this.getAiArticlesComments();
    }

    this.onResize()
  }

  checkLoadMore(comments){
    let count=0;
    if(this.articleComments && this.aiArticleDetails){

      for (let index = 0; index < comments.length; index++) {
        const element = comments[index];

        count+=element.childCommetsCount
        if(index == 1 || index == 0 && comments.length == 1){
          count+=comments.length;
          if(count == this.aiArticleDetails.articleCommentsCount && this.articleComments.length <= 2){
            this.loadedAllComments = true;
          }else if(count < this.aiArticleDetails.articleCommentsCount && !this.showLessComments){
            this.loadedAllComments = false;
          }
          break;
        }
      }

      if(this.articleComments.length <= 2 && this.showLessComments == true){
        this.showLessComments = false;
      }

      if(this.articleComments.length > 2 && !this.loadMoreButtonClicked){
        this.loadedAllComments = false;
      }

      if(this.articleComments.length == 0 && this.aiArticleDetails.articleCommentsCount == 0){
        this.loadedAllComments = true;
      }
    }
  }

  getAiArticlesComments(){
    this.aiArticlesCommentaryService.setCurrentCommentaryLoadMore(this.articleId)
    if(this.articleComments.length <=2 || this.addedComments == true){
      this.aiToolsApiFacade.getAiArticleComments(this.articleId);
    }else{
      this.limitSlice = this.articleComments.length;
      this.loadedAllComments = true;
      this.showLessComments =  true;
    }
    this.loadMoreButtonClicked = true;
  }

  onViewLess(){
    this.showAllcomments = false;
    this.currentCommentId = -1;
  }

  onViewLessComments(){
    this.limitSlice = 2;
    this.loadedAllComments = false;
    this.showLessComments =  false;
    this.loadMoreButtonClicked = false;
    this.addedComments = false;
    this.checkLoadMore(this.articleComments)
  }

  onViewAll(commentaryId){
    this.currentCommentId = commentaryId;
    this.showAllcomments = true;
    this.aiToolsApiFacade.getAiArticleChildComments(commentaryId, this.articleId)
  }

  onSaveComment(){
    let commentText= this.commentControl.value.replace(/>@/g,'>').replace(/&nbsp;/g,'');
    let comment ={
      "articleId": this.articleId,
      "parentId": null,
      "comment": commentText,
      "relatedUserEmail": this.userEmail
    }
    if(this.commentControl.value && this.commentControl.value.trim() != '' && this.commentControl.value != ''){
      this.aiToolsApiFacade.addAiArticleComment(comment);
      this.commentControl = new FormControl("")
      this.addedComments = true;
    }
  }

  onConfirmReply(comme:IAiArticleComment){
    let replyText= this.commentReplyControl.value.replace(/>@/g,'>').replace(/&nbsp;/g,'');
    let comment ={
      "articleId": this.articleId,
      "parentId": comme.id,
      "topParentId": comme.id,
      "comment": replyText,
      "relatedUserEmail": comme.userEmail
    }

    if(this.commentReplyControl.value && this.commentReplyControl.value.trim() != '' && this.commentReplyControl.value != ''){
      this.aiArticlesCommentaryService.setCurrentCommentaryReplyId(-0);
      this.aiToolsApiFacade.addAiArticleComment(comment);
    }
  }

  onEditConfirm(){
    let editText= this.commentEditControl.value.replace(/>@/g,'>').replace(/&nbsp;/g,'');
    let comme ={
      "commentId": this.commentToEdit.id,
      "comment": editText,
      "articleId": this.articleId
    }

    if(this.commentEditControl.value && this.commentEditControl.value.trim() != '' && this.commentEditControl.value != ''){
      this.aiToolsApiFacade.editAiArticleComment(comme);
      this.aiArticlesCommentaryService.setCurrentCommentaryEditId(-1);
    }

  }

  onEditStart(comment){
      this.commentToEdit = comment;
      this.commentEditControl = new FormControl(comment.comment);
      this.aiArticlesCommentaryService.setCurrentCommentaryEditId(comment.id);
  }

  onReplyStart(comment){
    this.commentReplyControl = new FormControl("")
    this.aiArticlesCommentaryService.setCurrentCommentaryReplyId(comment.id);
  }

  onEditCancel(){
    this.commentEditControl = new FormControl("");
    this.aiArticlesCommentaryService.setCurrentCommentaryEditId(-1);
  }

  onDeleteComment(){
    this.aiToolsApiFacade.deleteArticleComment(this.commentToDelete);
    this.modalRef?.hide();
  }

  AddCommentLike(comment: IAiArticleComment){
    this.aiToolsApiFacade.addAiArticleCommentLike(comment.id, comment.articleId, comment.userEmail).subscribe( result => {
      if(result == true){
        comment.isUserLike = true;
        comment.likesCount +=1;
      }
    })
  }

  RemoveCommentLike(comment: IAiArticleComment){
    this.aiToolsApiFacade.removeAiArticleCommentLike(comment.id).subscribe( result => {
      if(result == true){
        comment.isUserLike = false;
        comment.likesCount -=1;
      }
    })
  }

  openAlertModal(template: TemplateRef<any>, comment) {
    this.commentToDelete = comment;
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  openAllCommentsModal(template: TemplateRef<any>) {
    if (!this.isInsideOfModal) {
      this.mobileModalRef = this.modalService.show(template,{ignoreBackdropClick:true, animated:false});
    }
  }

  closeModal() {
    if (!this.isInsideOfModal) {
      this.mobileModalRef?.hide();
    }
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768;
  }
}
