import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AiToolsCommentaryService } from '../ai-tools-commentary.service';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { CkEditorService } from 'src/app/Shared/Services/ck-editor.service';
import { ISearchResultsType } from 'src/app/Shared/Models/search.interface';
import { UserGraphApiFacade } from 'src/app/Facade/GraphApiFacade/user-graph.facade';

@Component({
  selector: 'app-add-ai-tools-comment-box',
  templateUrl: './add-ai-tools-comment-box.component.html',
  styleUrls: ['./add-ai-tools-comment-box.component.scss']
})
export class AddAiToolsCommentBoxComponent implements OnInit {

  shiftKeyPressed = false;

  // @HostListener('window:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {

  //   if(event.key == "Escape"){
  //     this.onCancelReply()
  //   }

  //   if(event.key == "Enter" && this.shiftKeyPressed == false){
  //     this.removeEmpty(this.commentControl.value)
  //   }

  //   if(event.key == "Shift"){
  //     this.shiftKeyPressed = true;
  //   }
  // }

  handleKeyboardEvent(event: KeyboardEvent) {
      console.log(event);
      if(event.key == "Escape"){
        this.onCancelReply()
      }

      if(event.key == "Enter" && this.shiftKeyPressed == false){
        this.removeEmpty(this.commentControl.value)
      }

      if(event.key == "Shift"){
        this.shiftKeyPressed = true;
      }
    }
  // @HostListener('window:keyup', ['$event'])
  // handleKeyboardEvent1(event: KeyboardEvent) {
  //   if(event.key == "Shift"){
  //     this.shiftKeyPressed = false;
  //   }
  // }

  @ViewChild('AddAiToolsEditor', { static: false }) editor: CKEditorComponent|any;

  @Input() userEmail:string;
  @Input() toolId;
  @Input() commentControl:FormControl;
  @Input() isReply:boolean;

  userList:any;

  toggled: boolean = false;
  showEditor = false;
  boxFocus = false;
  @Output() onSaveEmitter = new EventEmitter(false)

  userPhotoSettings = {
    userPhotoStyles: {
      'width':'64px',
      'height':'64px'
    },
    size: 'middle'
  }

  public Editor;
  public ckConfig;

  searchText = '';
  shortSearchResults: ISearchResultsType[] = [];
  constructor(private aitoolsCommentaryService:AiToolsCommentaryService, private ckEditorService: CkEditorService, private graphUserFacade: UserGraphApiFacade) { }

  ngOnInit(): void {
    this.Editor =  this.ckEditorService.getSkEditor();
    this.ckConfig =  this.ckEditorService.getSkEditorConfig();
    this.aitoolsCommentaryService.commentarySource$.subscribe(state => {
      if(this.toolId != undefined && this.toolId != state.currentTextBoxFocused){
        this.resetTextBox();
      }
    })
    this.ckEditorService.dataSource.subscribe(state => {
      this.userList=state.value;
      console.log(this.userList);
    })

  }

  onCancelReply(){
    this.aitoolsCommentaryService.setCurrentCommentaryReplyId(-1);
  }
  handleUser(user){
    const selection = this.editor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
      this.editor.editorInstance.model.change(writer => {
        writer.insert("@"+user, range.start);
        this.userList = []
      });  }
    handleEmoji(event) {
    this.toggled = false;
    if(this.toolId != undefined){
      this.aitoolsCommentaryService.setCurrentBoxFocused(this.toolId)
    }
    const selection = this.editor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
      this.editor.editorInstance.model.change(writer => {
        writer.insert(event.char, range.start);
      });
  }

  onConfirm(){
    this.onSaveEmitter.emit(true)
  }

focusEvent(){
  this.boxFocus = true;
  this.aitoolsCommentaryService.setCurrentBoxFocused(this.toolId)
}
onEditorReady(editor) {
  this.ckEditorService.MentionCustomization(editor);
}

mouseEnter(){
  this.showEditor = true;
}

mouseLeave(){
  if(this.boxFocus == false){
   this.resetTextBox()
  }
}

resetTextBox(){
  this.commentControl.reset();
  this.showEditor = false;
  this.boxFocus = false;

}

onOpenEmojiBox(){
  this.toggled = !this.toggled
  if(this.toolId != undefined){
    this.showEditor = true;
  }
}

removeEmpty(str){
  if(str && str.includes('<p>&nbsp;</p>')){
    str = str.replace('<p>&nbsp;</p>', "")
    this.removeEmpty(str)
  }else{
    this.commentControl.setValue(str)
    this.onConfirm()
  }
}
findUser(){
// console.log("up")
}
// let emeailsInput = this.elem.nativeElement.querySelector('.classImLookingFor').val();
            // emeailsInput += modelAttributeValue.id+";";
            // this.elem.nativeElement.querySelector('.classImLookingFor').val(emeailsInput);
}
