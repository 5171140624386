import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppItemComponent } from './Components/app-item/app-item.component';
import { HighlightSearchPipe } from './Pipes/hightlight-search.pipe';
import { ConfirmComponent } from './Components/confirm/confirm.component';
import { SafeHtmlPipe } from './Pipes/safeHtml.pipe';
import { TruncateTextPipe } from './Pipes/truncateText.pipe';
import { PreloaderComponent } from './Components/preloader/preloader.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LinkedPeopleComponent } from './Components/linked-people/linked-people.component';
import { EmptyBoxComponent } from './Components/empty-box/empty-box.component';
import { UserPhotoComponent } from './Components/user-photo/user-photo.component';
import { UserPresenceComponent } from './Components/user-presence/user-presence.component';
import { DeleteAlertModalComponent } from './Components/delete-alert-modal/delete-alert-modal.component';
import { DisplayCkeditorContentComponent } from './Components/display-ckeditor-content/display-ckeditor-content.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ConfirmAlertModalComponent } from './Components/confirm-alert-modal/confirm-alert-modal.component';
import { SortDropdownFilterComponent } from './Components/sort-dropdown-filter/sort-dropdown-filter.component';
import { ImageVideoContainerComponent } from './Components/image-video-container/image-video-container.component';
import { RouterModule } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ShareButtonComponent } from './Components/share-button/share-button.component';
import { ClipboardModule } from 'ngx-clipboard';
import { AlertModule } from 'ngx-bootstrap/alert';
import { VodLikeComponent } from './Components/like/vod-like/vod-likes.component';
import { ArticleLikeComponent } from './Components/like/article-like/article-like.component';
import { BirthdaysComponent } from './Components/birthdays/birthdays.component';
import { GalleryLikeComponent } from './Components/like/gallery-like/gallery-likes.component';
import { VodCommentsComponent } from './Components/comment/vods/vod-comments.component';
import { CommentItemComponent } from './Components/comment/vods/comment-item/comment-item.component';
import { AddCommentBoxComponent } from './Components/comment/vods/add-comment-box/add-comment-box.component';
import { EditCommentBoxComponent } from './Components/comment/vods/edit-comment-box/edit-comment-box.component';
import { ArticleCommentsComponent } from './Components/comment/article/article-comments.component';
import { AddArticleCommentBoxComponent } from './Components/comment/article/add-article-comment-box/add-article-comment-box.component';
import { ArticleCommentItemComponent } from './Components/comment/article/article-comment-item/article-comment-item.component';
import { EditArticleCommentBoxComponent } from './Components/comment/article/edit-article-comment-box/edit-article-comment-box.component';
import { AiToolsCommentsComponent } from './Components/comment/ai-tools/ai-tools-comments.component';
import { AddAiToolsCommentBoxComponent } from './Components/comment/ai-tools/add-ai-tools-comment-box/add-ai-tools-comment-box.component';
import { AiToolsCommentItemComponent } from './Components/comment/ai-tools/ai-tools-comment-item/ai-tools-comment-item.component';
import { EditAiToolsCommentBoxComponent } from './Components/comment/ai-tools/edit-ai-tools-comment-box/edit-ai-tools-comment-box.component';
import { VodFavoriteComponent } from './Components/favorite/vod-favorite/vod-favorite/vod-favorite.component';
import { GalleryFavoriteComponent } from './Components/favorite/gallery-favorite/gallery-favorite.component';
import { ArticleFavoriteComponent } from './Components/favorite/article-favorite/article-favorite.component';
import { NgxEmojModule } from 'ngx-emoj';
import { EmojsComponent } from './Components/emojs/emojs.component';
import { VideoModalBoxComponent } from './Components/video-modal-box/video-modal-box.component';
import { CalendarDropdownFilterComponent } from './Components/calendar-dropdown-filter/calendar-dropdown-filter.component';
import { CalendarDropdownDirective } from './Components/calendar-dropdown-filter/calendar-dropdown.directive';
import { BirthdayWishModalComponent } from './Components/birthdays/birthday-wish-modal/birthday-wish-modal.component';
import { BirthdayWishesComponent } from './Components/birthdays/birthday-wishes/birthday-wishes.component';
import { BirthdayWishesModalComponent } from './Components/birthdays/birthday-wishes/birthday-wishes-modal/birthday-wishes-modal.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { WellbeingLikeComponent } from './Components/like/wellbeing-like/wellbeing-like.component';
import { ArticleCommentsMobileComponent } from './Components/comment/article/article-comments-mobile/article-comments-mobile.component';
import { VodsCommentsMobileComponent } from './Components/comment/vods/vods-comments-mobile/vods-comments-mobile.component';
import { UserNamesComponent } from './Components/user-names/user-names.component';
import { BackButtonComponent } from './Components/back-button/back-button.component';
import { MoreEventsModalComponent } from './Components/more-events-modal/more-events-modal.component';
import { CalendarEventItemComponent } from './Components/calendar-event-item/calendar-event-item.component';
import { PowerAppModalComponent } from './Components/power-app-modal/power-app-modal.component';
import { SafePipe } from './Pipes/safe.pipe';
import { TargetPreloaderComponent } from './Components/target-preloader/target-preloader.component';
import { AiToolsLikeComponent } from './Components/like/ai-tools-like/ai-tools-like.component';
import { AiToolsCommentsMobileComponent } from './Components/comment/ai-tools/ai-tools-comments-mobile/ai-tools-comments-mobile.component';
import { AiToolsCommentIndicatorComponent } from './Components/comment-indicator/ai-tools-comment-indicator/ai-tools-comment-indicator.component';
import { AiArticlesCommentsComponent } from './Components/comment/ai-articles/ai-articles-comments.component';
import { AddAiArticlesCommentBoxComponent } from './Components/comment/ai-articles/add-ai-articles-comment-box/add-ai-articles-comment-box.component';
import { AiArticlesCommentItemComponent } from './Components/comment/ai-articles/ai-articles-comment-item/ai-articles-comment-item.component';
import { EditAiArticlesCommentBoxComponent } from './Components/comment/ai-articles/edit-ai-articles-comment-box/edit-ai-articles-comment-box.component';
import { AiArticlesLikeComponent } from './Components/like/ai-articles-like/ai-articles-like.component';
import { AiArticlesFavoriteComponent } from './Components/favorite/ai-articles-favorite/ai-articles-favorite.component';
import { AiArticlesCommentsMobileComponent } from './Components/comment/ai-articles/ai-articles-comments-mobile/ai-articles-comments-mobile.component';
import { AiArticlesCommentIndicatorComponent } from './Components/comment-indicator/ai-articles-comment-indicator/ai-articles-comment-indicator.component';
import { TooltipOnEllipsisDirective } from './Directives/tooltip-on-ellipsis/tooltip-on-ellipsis.directive';
// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppItemComponent,
    HighlightSearchPipe,
    ConfirmComponent,
    SafeHtmlPipe,
    SafePipe,
    TruncateTextPipe,
    PreloaderComponent,
    LinkedPeopleComponent,
    EmptyBoxComponent,
    UserPhotoComponent,
    UserPresenceComponent,
    DeleteAlertModalComponent,
    DisplayCkeditorContentComponent,
    ConfirmAlertModalComponent,
    SortDropdownFilterComponent,
    ImageVideoContainerComponent,
    ShareButtonComponent,
    VodLikeComponent,
    ArticleLikeComponent,
    AiToolsLikeComponent,
    AiArticlesLikeComponent,
    BirthdaysComponent,
    GalleryLikeComponent,
    VodCommentsComponent,
    CommentItemComponent,
    AddCommentBoxComponent,
    EditCommentBoxComponent,
    ArticleCommentsComponent,
    AddArticleCommentBoxComponent,
    ArticleCommentItemComponent,
    EditArticleCommentBoxComponent,
    AiToolsCommentsComponent,
    AddAiToolsCommentBoxComponent,
    AiToolsCommentItemComponent,
    AiToolsCommentIndicatorComponent,
    AiArticlesCommentIndicatorComponent,
    EditAiToolsCommentBoxComponent,
    AiArticlesCommentsComponent,
    AddAiArticlesCommentBoxComponent,
    AiArticlesCommentItemComponent,
    EditAiArticlesCommentBoxComponent,
    VodFavoriteComponent,
    GalleryFavoriteComponent,
    ArticleFavoriteComponent,
    AiArticlesFavoriteComponent,
    EmojsComponent,
    VideoModalBoxComponent,
    CalendarDropdownFilterComponent,
    CalendarDropdownDirective,
    BirthdayWishModalComponent,
    BirthdayWishesComponent,
    BirthdayWishesModalComponent,
    WellbeingLikeComponent,
    ArticleCommentsMobileComponent,
    AiArticlesCommentsMobileComponent,
    AiToolsCommentsMobileComponent,
    VodsCommentsMobileComponent,
    UserNamesComponent,
    BackButtonComponent,
    MoreEventsModalComponent,
    CalendarEventItemComponent,
    PowerAppModalComponent,
    TargetPreloaderComponent,
    TooltipOnEllipsisDirective,
  ],
  imports: [
    CommonModule,
    InfiniteScrollModule,
    FormsModule,
    CKEditorModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    RouterModule,
    TooltipModule,
    ClipboardModule,
    AlertModule,
    NgxEmojModule,
    AutoCompleteModule,
  ],
  exports: [
    AppItemComponent,
    HighlightSearchPipe,
    ConfirmComponent,
    SafeHtmlPipe,
    SafePipe,
    TruncateTextPipe,
    PreloaderComponent,
    InfiniteScrollModule,
    LinkedPeopleComponent,
    EmptyBoxComponent,
    UserPhotoComponent,
    UserPresenceComponent,
    DeleteAlertModalComponent,
    DisplayCkeditorContentComponent,
    ConfirmAlertModalComponent,
    SortDropdownFilterComponent,
    ImageVideoContainerComponent,
    TooltipModule,
    ClipboardModule,
    ShareButtonComponent,
    AlertModule,
    VodLikeComponent,
    ArticleLikeComponent,
    AiToolsLikeComponent,
    AiArticlesLikeComponent,
    BirthdaysComponent,
    BirthdayWishesComponent,
    GalleryLikeComponent,
    VodCommentsComponent,
    ArticleCommentsComponent,
    AiToolsCommentsComponent,
    AiArticlesCommentsComponent,
    AiToolsCommentIndicatorComponent,
    AiArticlesCommentIndicatorComponent,
    VodFavoriteComponent,
    GalleryFavoriteComponent,
    ArticleFavoriteComponent,
    AiArticlesFavoriteComponent,
    VideoModalBoxComponent,
    CalendarDropdownFilterComponent,
    BirthdayWishesModalComponent,
    WellbeingLikeComponent,
    UserNamesComponent,
    BackButtonComponent,
    MoreEventsModalComponent,
    CalendarEventItemComponent,
    PowerAppModalComponent,
    TargetPreloaderComponent,
    TooltipOnEllipsisDirective
  ],
})
export class SharedModule {}
