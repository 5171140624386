<!-- <button (click)="loginRedirect()" *ngIf="!loginDisplay">login</button>
<button mat-raised-button (click)="logout()" *ngIf="loginDisplay">Logout</button>


<a [routerLink]="['profile']" *ngIf="loginDisplay">Profile</a> -->
<app-start-preloader></app-start-preloader>

<div class="app-wrapper">
  <div>
    <global-sidebar-with-dashboard (closeModalEmiter)="closeHeaderModalEmitter()"></global-sidebar-with-dashboard>
    <div class="profile-filler"></div>
  </div>
  <div class="app-container">
    <app-header [modalHeaderService]="modalHeaderService"></app-header>
      <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <router-outlet *ngIf="!isIframe"></router-outlet>
    <app-scroll-to-top></app-scroll-to-top>
  </div>
</div>


