import { DatePipe } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { UserApiFacade } from 'src/app/Facade/ApiFacade/user.facade';
import { IAddBirthdayWish, IEmployeeBirthdaysData } from 'src/app/Shared/Models/user.model';
import { CkEditorService } from 'src/app/Shared/Services/ck-editor.service';
import { PreloaderService } from 'src/app/Shared/Services/preloader.service';
import { UtitlityService } from 'src/app/Shared/Services/utilitiy.service';

@Component({
  selector: 'app-birthday-wish-modal',
  templateUrl: './birthday-wish-modal.component.html',
  styleUrls: ['./birthday-wish-modal.component.scss']
})
export class BirthdayWishModalComponent implements OnInit, OnDestroy {

  shiftKeyPressed = false;
  currentIndex = 0;
  isHebrewText = false;
  birthdayCake = 'https://stplaytika360.blob.core.windows.net/360images/TEST/PersonalCommentSentBCake.png';

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {

    if(event.key == "Enter" && this.shiftKeyPressed == false){
      this.removeEmpty(this.withFormControls[this.currentIndex-1].value)
    }

    if(event.key == "Shift"){
      this.shiftKeyPressed = true;
    }

  }

  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent1(event: KeyboardEvent) {
    if(event.key == "Shift"){
      this.shiftKeyPressed = false;
    }
  }
  
  @Output() onCloseModalEmmiter = new EventEmitter<boolean>();
  @Input() recentPeopleBirthdays:IEmployeeBirthdaysData[];
  boxFocus = false;

  editor: CKEditorComponent|any;
  
  withFormControls:FormControl[]=[];

  toggled: boolean = false;
  currentUser:IEmployeeBirthdaysData;
  successSent = false;

  userPhotoSettings = {
    userPhotoStyles: {
      'width':'41px',
      'height':'41px',
      'border':'solid 2.6px #e3e7ec',
      'border-radius': '50%',
      'padding': '2px'
    },
    size: 'smaller'
  };

  public Editor;
  public ckConfig;

  constructor(private ckEditorService: CkEditorService, 
    private utitlityService:UtitlityService,
    private preloaderService: PreloaderService,private userApiFacade:UserApiFacade) { }
 
  ngOnInit(): void {
    this.Editor =  this.ckEditorService.getSkEditor();
    this.ckConfig =  this.ckEditorService.getSkEditorConfig();
    this.ckConfig.placeholder = 'Write a message'

    for (let index = 0; index < this.recentPeopleBirthdays.length; index++) {
      const element = this.recentPeopleBirthdays[index];
      element.successInformation = undefined;
      this.withFormControls.push(new FormControl("",[Validators.required]))
    }
  }

  handleEmoji(event) {
    this.toggled = false;
    const selection = this.editor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
      this.editor.editorInstance.model.change(writer => {
        writer.insert(event.char, range.start);
      });
  }

  
  onOpenEmojiBox(birthday){

    if(this.currentUser && this.currentUser.userEmail != birthday.userEmail){
      this.toggled = false;
    }

    this.toggled = !this.toggled

    let ckEditor = document.getElementById(birthday.userEmail)
    if(ckEditor){
      ckEditor.click();
    }
  }

  clickEvent(index, birthday, editor){
    this.recentPeopleBirthdays[index].successInformation = undefined;
    this.editor = editor;
    if(this.currentIndex  > 0 && this.currentUser.userEmail != birthday.userEmail){
      this.withFormControls[this.currentIndex-1].reset();
    }

    this.currentUser = birthday;
    this.successSent = false;
    this.currentIndex = index+1;
    this.currentUser = birthday;
  }

  focusEvent(birthday){
    if(this.currentUser && this.currentUser.userEmail != birthday.userEmail){
      this.isHebrewText = false;
      this.toggled = false;
    }
  }


  removeEmpty(str){
    if(str && str.includes('<p>&nbsp;</p>')){
      str = str.replace('<p>&nbsp;</p>', "")
      this.removeEmpty(str)
    }else{
      this.withFormControls[this.currentIndex-1].setValue(str)
      this.onConfirm()
    }
  }

  onSendCake(wishIcon?, birthdayUser?, index?){
    this.onConfirm(wishIcon, birthdayUser, index);
  }

  onConfirm(wishIcon?, birthdayUser?, index?){
    
    if(birthdayUser){
      this.currentUser = birthdayUser;
    }

    let datepipe = new DatePipe('en-US');
    let year = datepipe.transform(Date.now(),'YYYY');
    let dayAndMonth = datepipe.transform(this.currentUser.dateOfBirth,'MM-ddTHH:mm:ss.ms')+'Z';
    let birthday = `${year}-${dayAndMonth}`

    if(this.withFormControls[this.currentIndex-1] && this.withFormControls[this.currentIndex-1].valid || (wishIcon && birthdayUser)){
      this.preloaderService.showPreloader()
      let birthdayWish:IAddBirthdayWish = {
        birthdayUser:this.currentUser.userEmail,
        message: this.withFormControls[this.currentIndex-1] != undefined && this.withFormControls[this.currentIndex-1].valid
        ? this.withFormControls[this.currentIndex-1].value
        : 'Happy Birthday',
        birthday:birthday,
        wishingIcon:wishIcon != undefined
        ? this.birthdayCake
        : null
      }

      this.userApiFacade.addBirthdayWish(birthdayWish).subscribe(result => {
        if(this.withFormControls[this.currentIndex-1]){
          this.withFormControls[this.currentIndex-1].reset();
        }

        let i = index == undefined ? this.currentIndex-1 : index
        this.successSent = result;
        this.recentPeopleBirthdays[i].successInformation = `Your Birthday Wish was successfully sent`
        this.preloaderService.hidePreloader()
      })

    }
  }

  isHebrew(){
    if(this.withFormControls[this.currentIndex-1] && this.withFormControls[this.currentIndex-1].value != null){
      this.isHebrewText = this.utitlityService.isHebrew(this.withFormControls[this.currentIndex-1].value)
    }
  }

  ngOnDestroy(): void {
    this.ckConfig.placeholder ='Write your comment'
  }

}
