import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, HostListener, TemplateRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VodsApiFacade } from 'src/app/Facade/ApiFacade/vods.facade';
import { IVodComment } from 'src/app/Shared/Models/vod-item.interface';
import { UserProfileService } from 'src/app/Shared/Services/user-profile.service';
import { CommentaryService } from '../commentary.service';


@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit {

  @Input() parentId;
  @Input() vodId;
  currentCommentIdReply;
  currentCommentIdEdit;

  commentAnswerControl = new FormControl("");
  commentEditControl = new FormControl("");
  commentToDelete:IVodComment;
  commentToEdit:IVodComment;
  allComments:IVodComment[];
  vodComments:IVodComment[];
  userEmail:string;
  isParentDeleted:boolean = false;

  modalRef?: BsModalRef;
  isMobile: boolean = false;

  datepipe: DatePipe = new DatePipe('en-US')
  today = this.datepipe.transform(Date.now(),'yyyy-MM-dd')?.split('T')[0]


  userPhotoSettings = {
    userPhotoStyles: {
      'width':'38px',
      'height':'38px'
    },
    size: 'smaller'
  }

  constructor(private vodsApiFacade:VodsApiFacade,
    private commentaryService:CommentaryService,
    private modalService: BsModalService,
    private userProfileService:UserProfileService) { }

  ngOnInit(): void {

    this.vodsApiFacade.currentVodCommentsSource$.subscribe(source => {
      var coment =  source.find(c => c.vodId == this.vodId)
      if(coment){
        this.vodComments = coment.comments.filter(comment=> comment.parentId == this.parentId);
        this.allComments = coment.comments;
        this.isParentDeleted = coment.comments.find(c => c.id == this.parentId && c.deleted == true) != undefined;
      }
    })

    this.userProfileService.userProfileSource$.subscribe(user => {
      this.userEmail = user.userPrincipalName;
    })

    this.commentaryService.commentarySource$.subscribe(state => {
      this.currentCommentIdReply = state.currentCommentIdReply;
      this.currentCommentIdEdit = state.currentCommentIdEdit;
    })

    this.onResize()
  }

  onDeleteComment(){
    this.vodsApiFacade.deleteComment(this.commentToDelete);
    this.modalRef?.hide();
  }

  onReplyStart(comment){
    this.commentAnswerControl = new FormControl("")
    this.commentaryService.setCurrentCommentaryReplyId(comment.id);
  }

  onConfirmReply(comme:IVodComment){
    let comment ={
      "vodId": this.vodId,
      "parentId": comme.id,
      "topParentId": comme.topParentId,
      "comment": this.commentAnswerControl.value
    }

    if(this.commentAnswerControl.value.trim() != '' && this.commentAnswerControl.value != ''){
      this.vodsApiFacade.addVodComment(comment);
      this.commentaryService.setCurrentCommentaryReplyId(-0);
    }
  }

  onEditConfirm(){
    let comme ={
      "commentId": this.commentToEdit.id,
      "comment": this.commentEditControl.value,
      "vodId":this.vodId
    }

    if(this.commentEditControl.value.trim() != '' && this.commentEditControl.value != ''){
      this.vodsApiFacade.editVodComment(comme);
      this.commentaryService.setCurrentCommentaryEditId(-1);
    }

  }

  onEditStart(comment){
    this.commentToEdit = comment;
      this.commentEditControl = new FormControl(comment.comment);
      this.commentaryService.setCurrentCommentaryEditId(comment.id);
  }

  onEditCancel(){
    this.commentEditControl = new FormControl("");
    this.commentaryService.setCurrentCommentaryEditId(-1);
  }

  AddCommentLike(comment: IVodComment){
    this.vodsApiFacade.addVodCommentLike(comment.id, comment.vodId, comment.userEmail).subscribe( result => {
      if(result == true){
        comment.isUserLike = true;
        comment.likesCount +=1;
      }
    })
  }

  RemoveCommentLike(comment: IVodComment){
    this.vodsApiFacade.removeVodCommentLike(comment.id).subscribe( result => {

      console.log(result)
      if(result == true){
        comment.isUserLike = false;
        comment.likesCount -=1;
        console.log(comment)
      }
    })
  }

  hasChildrem(commentId){
    return this.allComments.find(c => c.parentId == commentId) != undefined;
  }

  openAlertModal(template: TemplateRef<any>, comment) {
    this.commentToDelete = comment;
    this.modalRef = this.modalService.show(template,{ignoreBackdropClick:true});
  }

  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768;
  }
}
