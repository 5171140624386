import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './Components/header/header.component';
import { ProfileComponent } from './Components/profile/profile.component';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SearchHeaderComponent } from './Components/header/search-header/search-header.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { playerFactory, SharedModule } from '../Shared/shared.module';
import { MobileMenuComponent } from './Components/mobile-menu/mobile-menu.component';
import { StartPreloaderComponent } from './Components/start-preloader/start-preloader.component';
import { LottieModule } from 'ngx-lottie';
import { WikiNavMenuComponent } from './Components/wiki-nav-menu/wiki-nav-menu.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { MyAppsComponent } from './Components/my-apps/my-apps.component';
import { HeaderModalComponent } from './Components/header/header-modal/header-modal.component';
import { SidebarDashboardComponent } from './Components/sidebar-dashboard/sidebar-dashboard.component';
import { SidebarWithDashboardComponent } from './Components/sidebar-with-dashboard/sidebar-with-dashboard.component';
import { SidebarDashboardCalendarComponent } from './Components/sidebar-dashboard-calendar/sidebar-dashboard-calendar.component';
import { NavMenuComponent } from './Components/nav-menu/nav-menu.component';
import { ScrollToTopComponent } from './Components/scroll-to-top/scroll-to-top.component';
import { DashboardHeaderComponent } from './Components/dashboard-header/dashboard-header.component';
import { DashboardShortcutsComponent } from './Components/dashboard-shortcuts/dashboard-shortcuts.component';
import { SwiperModule } from 'swiper/angular';
import { DashboardContentComponent } from './Components/dashboard-content/dashboard-content.component';
import { DashboardUserInfoComponent } from './Components/dashboard-user-info/dashboard-user-info.component';
import { DashboardActivitiesComponent } from './Components/dashboard-activities/dashboard-activities.component';
import { DashboardEventsComponent } from './Components/dashboard-events/dashboard-events.component';
import { MoreEventsModalComponent } from '../Shared/Components/more-events-modal/more-events-modal.component';
import { DashboardCibusComponent } from './Components/dashboard-cibus/dashboard-cibus.component';
import { DashboardComingSoonComponent } from './Components/dashboard-coming-soon/dashboard-coming-soon.component';
import { DashboardCalendarPlaceholderComponent } from './Components/dashboard-calendar-placeholder/dashboard-calendar-placeholder.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ProfileComponent,
    SearchHeaderComponent,
    MobileMenuComponent,
    StartPreloaderComponent,
    WikiNavMenuComponent,
    MyAppsComponent,
    HeaderModalComponent,
    SidebarDashboardComponent,
    SidebarWithDashboardComponent,
    SidebarDashboardCalendarComponent,
    NavMenuComponent,
    ScrollToTopComponent,
    DashboardHeaderComponent,
    DashboardShortcutsComponent,
    DashboardContentComponent,
    DashboardUserInfoComponent,
    DashboardActivitiesComponent,
    DashboardEventsComponent,
    DashboardCibusComponent,
    DashboardComingSoonComponent,
    DashboardCalendarPlaceholderComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    PopoverModule.forRoot(),
    AutoCompleteModule,
    SharedModule,
    LottieModule.forRoot({ player: playerFactory }),
    SwiperModule
  ],
  exports:[
   HeaderComponent,
   ProfileComponent,
   StartPreloaderComponent,
   HeaderModalComponent,
   SidebarDashboardComponent,
   SidebarWithDashboardComponent,
   ScrollToTopComponent
  ],
  providers: [

  ],
})
export class CoreModule { }
