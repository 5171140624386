<ul class="nav-menu" [ngClass]="{ mobile: isMobile }" *ngIf="mainMenu && mainMenu.length > 0">
<ng-container *ngFor="let item of mainMenu">
  <li *ngIf="item.showOnMobile == true && isMobile==true">
    <a (click)="closeDashboardPlease()" *ngIf="!item.isExternal" routerLink="{{item.link}}" [routerLinkActive]="['nav-menu-item--active']" [routerLinkActiveOptions]="{ exact: true }"
       #activeItem="routerLinkActive" class="nav-menu-item">
       <div class="nav-menu-item__red-circle" *ngIf="item.link=='/notifications' && notificationsCountToSee > 0">{{notificationsCountToSee}}</div>
      <div class="nav-menu-item__red-circle" *ngIf="item.link=='/tc' && tasksCountToSee > 0">{{tasksCountToSee}}</div>
      <img *ngIf="!activeItem.isActive" onerror="this.onerror=null;this.src='/assets/images/global/spinner.gif';" class="nav-menu-item__icon" [src]="item.icon">
      <img *ngIf="activeItem.isActive" onerror="this.onerror=null;this.src='/assets/images/global/spinner.gif';" class="nav-menu-item__icon" [src]="item.iconActive">

      <div class="nav-menu-item__item-text"> {{item.title}}</div>
      <div *ngIf="item.link=='/notifications' && birthdayCount > 0">
        <img class="birthday-cake" src="/assets/images/global/birthday-cake.png" alt="">
      </div>
    </a>

    <a (click)="closeDashboardPlease()" *ngIf="item.isExternal" href="{{item.link}}" target="_blank" class="nav-menu-item">
      <img class="nav-menu-item__icon" onerror="this.onerror=null;this.src='/assets/images/global/spinner.gif';" [src]="item.icon">
      <div class="nav-menu-item__text"> {{item.title}}</div>
    </a>
  </li>

  <li *ngIf="!isMobile">
    <a (click)="closeDashboardPlease()" *ngIf="!item.isExternal" routerLink="{{item.link}}" [routerLinkActive]="['nav-menu-item--active']" [routerLinkActiveOptions]="{ exact: true }"
       #activeItem="routerLinkActive" class="nav-menu-item">
       <div class="nav-menu-item__red-circle" *ngIf="item.link=='/notifications' && notificationsCountToSee > 0">{{notificationsCountToSee}}</div>
      <div class="nav-menu-item__red-circle" *ngIf="item.link=='/tc' && tasksCountToSee > 0">{{tasksCountToSee}}</div>
      <img onerror="this.onerror=null;this.src='/assets/images/global/spinner.gif';" class="nav-menu-item__icon" [src]="isDashboardOpened ? item.iconWhite : item.icon">
      <!-- <img *ngIf="activeItem.isActive" onerror="this.onerror=null;this.src='/assets/images/global/spinner.gif';" class="nav-menu-item__icon" [src]="item.iconActive"> -->

      <div class="nav-menu-item__text"> {{item.title}}</div>
      <div *ngIf="item.link=='/notifications' && birthdayCount > 0">
        <img class="birthday-cake" src="/assets/images/global/birthday-cake.png" alt="">
      </div>
    </a>

    <a (click)="closeDashboardPlease()" *ngIf="item.isExternal" href="{{item.link}}" target="_blank" class="nav-menu-item">
      <img class="nav-menu-item__icon" onerror="this.onerror=null;this.src='/assets/images/global/spinner.gif';" [src]="isDashboardOpened ? item.iconWhite : item.icon">
      <div class="nav-menu-item__text"> {{item.title}}</div>
    </a>
  </li>

</ng-container>

</ul>
