import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CommentaryService } from '../commentary.service';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { CkEditorService } from 'src/app/Shared/Services/ck-editor.service';


@Component({
  selector: 'app-add-comment-box',
  templateUrl: './add-comment-box.component.html',
  styleUrls: ['./add-comment-box.component.scss']
})
export class AddCommentBoxComponent implements OnInit {

  shiftKeyPressed = false;
  // @HostListener('window:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {

  //   if(event.key == "Escape"){
  //     this.onCancelReply()
  //   }

  //   if(event.key == "Enter" && this.shiftKeyPressed == false){
  //     this.removeEmpty(this.commentControl.value)
  //   }

  //   if(event.key == "Shift"){
  //     this.shiftKeyPressed = true;
  //   }

  // }

  // @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent1(event: KeyboardEvent) {
    if(event.key == "Escape"){
      this.onCancelReply()
    }

    if(event.key == "Enter" && this.shiftKeyPressed == false){
      this.removeEmpty(this.commentControl.value)
    }

    if(event.key == "Shift"){
      this.shiftKeyPressed = true;
    }
  }

  @ViewChild('AddVodEditor', { static: false }) editor: CKEditorComponent|any;

  @Input() userEmail:string;
  @Input() vodId;
  @Input() commentControl:FormControl;
  @Input() isReply:boolean;

  toggled: boolean = false;
  showEditor = false;
  boxFocus = false;
  @Output() onSaveEmitter = new EventEmitter(false)

  userPhotoSettings = {
    userPhotoStyles: {
      'width':'38px',
      'height':'38px'
    },
    size: 'smaller'
  }

  public Editor;
  public ckConfig;

  constructor(private ckEditorService: CkEditorService,private commentaryService:CommentaryService) { }

  ngOnInit(): void {
    this.Editor =  this.ckEditorService.getSkEditor();
    this.ckConfig =  this.ckEditorService.getSkEditorConfig();

    this.commentaryService.commentarySource$.subscribe(state => {
      if(this.vodId != undefined && this.vodId != state.currentTextBoxFocused){
        this.resetTextBox();
      }
    })
  }

  onCancelReply(){
    this.commentaryService.setCurrentCommentaryReplyId(-1);
  }

  handleEmoji(event) {
    if(this.vodId != undefined){
      this.commentaryService.setCurrentBoxFocused(this.vodId)
    }
    this.toggled =false;
    const selection = this.editor.editorInstance.model.document.selection;
    const range = selection.getFirstRange();
      this.editor.editorInstance.model.change(writer => {
        writer.insert(event.char, range.start);
      });
  }

  onConfirm(){
    this.onSaveEmitter.emit(true)
  }

  focusEvent(){
    this.boxFocus = true;
    this.commentaryService.setCurrentBoxFocused(this.vodId)
  }
  
  mouseEnter(){
    this.showEditor = true;
  }

  mouseLeave(){
    if(this.boxFocus == false){
     this.resetTextBox()
    }
  }
  
  resetTextBox(){
    this.commentControl.reset();
    this.showEditor = false;
    this.boxFocus = false;
  }
  
  onOpenEmojiBox(){
    this.toggled = !this.toggled
    if(this.vodId != undefined){
      this.showEditor = true;
    }
  }

  removeEmpty(str){
    if(str && str.includes('<p>&nbsp;</p>')){
      str = str.replace('<p>&nbsp;</p>', "")
      this.removeEmpty(str)
    }else{
      this.commentControl.setValue(str)
      this.onConfirm()
    }
  }

}
