export const environment = {
  production: true,
  appInsights: {
    instrumentationKey: 'e491e158-c7b7-4a4a-abfa-7cac990d8fb6',
  },
  AADClientId: '81b2770c-f513-4dc7-ac40-f6bd745add64',
  AADAuthority:
    'https://login.microsoftonline.com/02f22272-3538-4a5f-ae4e-64cd13d9890e',
  GraphAPIURL: 'https://graph.microsoft.com/v1.0/',
  sharepointSiteId:
    'playtika.sharepoint.com,1180e9c1-23ad-4236-a266-b31a9a444a9b,a70dbc77-84e2-4ecc-8ea5-4307f11db1db',
  ApiPath: 'https://app-playtika360-prod-api.azurewebsites.net/',
  ApiScope: 'api://e9ce278c-4730-4905-9059-051c5f4a1b7b/user_impersonation',
  SharePoint: {
    SiteURL: 'playtika.sharepoint.com',
    ContentSiteId:
      '1180e9c1-23ad-4236-a266-b31a9a444a9b,a70dbc77-84e2-4ecc-8ea5-4307f11db1db',
    MainBanners: 'MainBanners',
    ArticlesListId: '33131966-8945-4275-8dbf-ae2a846fb812',
    SideBanners: 'SideBanners',
    Articles: 'PlaytikaArticels',
    wikiNavMenu: 'wikiNavMenu',
    wikiNavigation: 'wikiNavigation',
    wikiContent: 'wikiContent',
    Events: 'PlaytikaEvents',
    Announcements: 'Announcements',
    SideItems: 'SideItems',
    SideItemsCategories: 'SideItemsCategories',
    Categories: 'Categories',
    MainMenu: 'MainMenu',
    Calendar: 'PlaytikaCalendar',
    SidePopupBanners: 'SidePopup',
    DocumentLibery: 'DocLibrary',
    PlaytikaVOD: 'PlaytikaVOD',
    PlaytikaVODLibraryId: '062a92dd-6b36-4ab7-b400-f034c03351f0',
    DocumentLibraryId: '70a668c7-b2fa-4fc4-a4e1-2f9106ee9912',
    PlaytikaGalleryId: '230414c5-df4a-45f0-a1b4-3da9e56fe15d',
    PlaytikaGallery: 'PlaytikaGallery',
    SpecialBanners: 'SpecialBanners',
  },

  PowerApp: {
    DocumentFormId: '6c204da9-151b-4394-86a3-0ec0e237f1db',
    CompanyCalendarFormID: '803a6797-f322-4c74-bdec-c9ba3d8a32a1',
    DeleteCompanyCalendarEventID: 'eea0527e-ad50-4b13-be9c-bda1befa56dc',
    Enabled: true,
  },
  API: {
    User: {
      Profile: 'me',
      People: 'people',
      Users: 'users',
      Photo: 'photos/96x96/$value',
      DirectReports: 'directReports',
      UserProfile: 'GetUserProfile',
      GetMostConnectedBirthdays: 'GetMostConnectedBirthdays',
      Info: 'GetProfileInfo',
      UserApps: 'user-apps',
      Tasks: 'tasks',
      Communications: 'communications',
      GetUser: 'GetUser',
      AddMostConnectedPeople: 'AddMostConnectedPeople',
      GetMostConnectedPeople: 'GetMostConnectedPeople',
      GetDirectReports: 'GetDirectReports',
      GetManagers: 'GetManagers',
      GetManager: 'GetManager',
      AddBirthdayWish: 'AddBirthdayWish',
      GetUserBirthday: 'GetUserBirthday',
      GetUserBirthdayWishes: 'GetUserBirthdayWishes',
      GetCountUserBirthdayWishes: 'GetCountUserBirthdayWishes',
      SetReadBirthdayWishes: 'SetReadBirthdayWishes',
    },
    Wiki: {
      WikiSiteList: 'wikiSitesLists',
    },
    Apps: {
      Create: 'CreateApps',
      Get: 'GetUserApps',
      AddFavorite: 'AddFavoriteApps',
      Remove: 'RemoveApps',
      OrderApps: 'OrderApps',
    },
    SearchAll: 'SearchAll',
    Tasks: {
      GetUserTasks: 'GetUserTasks',
      GetUserTasksSummary: 'GetUserTasksSummary',
      GetTasksApps: 'GetTasksApps',
      SetTasksUserSeen: 'SetTasksUserSeen',
      GetCountTasksToSee: 'GetCountTasksToSee',
    },
    Announcements: {
      GetAnnouncements: 'GetAnnouncements',
      GetAnnouncementsByCategory: 'GetAnnouncementByCategory',
    },
    MainBanners: {
      GetMainBanners: 'GetMainBanners',
    },
    SideBanners: {
      GetSideBanners: 'GetSideBanners',
    },
    SidePopup: {
      GetSidePopup: 'GetSidePopup',
    },
    SpecialBanners: {
      GetBirthdayBanner: 'GetBirthdayBanner',
      GetMainMenuBanner: 'GetMainMenuBanner',
    },
    SideItems: {
      GetSideItems: 'GetSideItems',
      GetSideItemsCategories: 'GetSideItemsCategories',
    },
    MainMenu: {
      GetMainMenu: 'GetMainMenu',
    },
    Articles: {
      GetArticles: 'GetArticles',
      GetArticleById: 'GetArticleById',
      GetArticlesByCategory: 'GetArticlesByCategory',
      GetArticlesByKeywords: 'GetArticlesByKeywords',
      GetArticlesByCategories: 'articles/categories',
      GetArticleDetails: 'GetArticleDetails',
      GetArticleComments: 'GetArticleComments',
      AddArticleComment: 'AddArticleComment',
      UpdateArticleComment: 'UpdateArticleComment',
      DeleteArticleComment: 'DeleteArticleComment',
      AddArticleLike: 'AddArticleLike',
      RemoveArticleLike: 'RemoveArticleLike',
      DeleteArticleCommentWithChildren: 'DeleteArticleCommentWithChildren',
      GetChildComments: 'GetArticleChildComments',
      AddArticleFavorite: 'AddArticleFavorite',
      RemoveArticleFavorite: 'RemoveArticleFavorite',
      AddArticleCommentLike: 'AddArticleCommentLike',
      RemoveArticleCommentLike: 'RemoveArticleCommentLike',
      GetFavoriteArticles: 'GetFavoriteArticles',
      GetArticleLikedUserList: 'GetArticleLikedUserList',
      GetMostViewedArticles: 'articles/topViewed',
    },
    Notifications: {
      GetNewUserNotificationsCount: 'GetNewUserNotificationsCount',
      SetNotificationsReaded: 'AddNotificationsUserSeen',
      GetNewUserNotifications: 'GetNewUserNotifications',
      GetMentionedArticlesNotifications: 'GetMentionedArticlesNotifications',
      GetMentionedVodsNotifications: 'GetMentionedVodsNotifications',
      ReadMentionedArticlesNotifications: 'ReadMentionedArticlesNotifications',
      ReadMentionedVodsNotifications: 'ReadMentionedVodsNotifications',
      GetMentionNotifications: 'GetLikeCommentNotifications',
    },
    Vods: {
      GetVodDetails: 'GetVodDetails',
      AddVodLike: 'AddVodLike',
      RemoveVodLike: 'RemoveVodLike',
      GetVodComments: 'GetVodComments',
      AddVodComment: 'AddVodComment',
      UpdateVodComment: 'UpdateVodComment',
      DeleteVodCommentWithChildren: 'DeleteVodCommentWithChildren',
      DeleteVodComment: 'DeleteVodComment',
      GetChildComments: 'GetChildComments',
      AddVodFavorite: 'AddVodFavorite',
      RemoveVodFavorite: 'RemoveVodFavorite',
      AddVodCommentLike: 'AddVodCommentLike',
      RemoveVodCommentLike: 'RemoveVodCommentLike',
      GetFavoriteVods: 'GetFavoriteVods',
      GetVods: 'vods',
      GetVodsByCategory: 'vods/categories',
      GetVodsByKeywords: 'GetVodsByKeywords',
      GetVodsHomePage: 'vods/homeVods',
      GetVodLikedUserList: 'GetVodLikedUserList',
    },
    Galleries: {
      GetGalleryDetails: 'GetGalleryDetails',
      AddGalleryLike: 'AddGalleryLike',
      RemoveGalleryLike: 'RemoveGalleryLike',
      AddGalleryFavorite: 'AddGalleryFavorite',
      RemoveGalleryFavorite: 'RemoveGalleryFavorite',
      GetFavoriteGalleries: 'GetFavoriteGalleries',
      GetGalleries: 'galleries',
      GetGalleriesByCategory: 'galleries/categories',
      GetGalleriesByKeywords: 'GetGalleriesByKeywords',
      GetGalleriesHomePage: 'galleries/galleriesHome',
      GetGalleryLikedUserList: 'GetGalleryLikedUserList',
      GetLastUpdatedGallery: 'GetLastUpdatedGallery',
    },
    Calendar: {
      GetCompanyCalendar: 'GetCompanyCalendar',
    },
    Wellbeing: {
      GetWellbeingBanners: 'wellBeing/banners',
      GetPopularActivities: 'wellBeing/events/popular',
      GetWellbeingCategories: 'wellBeing',
      GetWellbeingEventsByCategory: 'wellBeing/events/category',
      AddWellbeingEventLike: 'wellBeing/addWellbeingEventLike',
      RemoveWellbeingEventLike: 'wellBeing/removeWellbeingEventLike',
      GetWellbeingEventDetails: 'wellBeing/getWellbeingEventDetails',
      GetWellbeingEvent: 'wellBeing/event',
      GetWellbeingEventsByKeywords: 'GetWellbeingEventsByKeywords',
      GetWellbeingLikedUserList: 'wellBeing/likedUserList',
    },
    OutLookEvent: {
      GetOutLookEvent: 'outLookEvents/event',
      CreateOutLookEvent: 'outLookEvents/createEvent',
      UpdateOutLookEvent: 'outLookEvents/updateEvent',
    },
    Departments: {
      GetDepartments: 'departments',
      GetDepartmentsFiles: 'departments/files',
      FilterDepartmentsFiles: 'departments/filterFiles',
      SearchFiles: 'departments/searchFiles',
      GetDepartmentData: 'departments/departmentData',
      GetDepartmentMedia: 'departments/media',
      GetDepartmentsWithSubdepartments: 'departmentsWithSubdepartments',
    },
    AiTools: {
      GetAiTools: 'ai-tools',
      GetAiTool: 'ai-tools/tool',
      GetTools: 'ai-tools/tools',
      GetFeaturedTools: 'ai-tools/featuredTools',
      SearchTools: 'ai-tools/search-tools',
      GetAiHomeData: 'ai-tools/home-data',
      GetAiToolsMedia: 'ai-tools/articles',
      GetFeaturedAiArticle: 'ai-tools/articles/featured-article',
      GetAiNewsArticles: 'ai-tools/articles/articles-news',
      GetAiNewsArticlesCount: 'ai-tools/articles/articles-news/count',
      GetAiToolsLinks: 'ai-tools/links',
      GetAiToolsBanner: 'ai-tools/banner',
      GetAiSideMenu: 'ai-tools/side-menu',
      GetAiSearchAnnouncement: 'ai-tools/search-announcement',
      GetAiToolDetails: 'ai-tools/details',
      GetAiToolComments: 'ai-tools/comments',
      AddAiToolComment: 'ai-tools/add/comment',
      UpdateAiToolComment: 'ai-tools/update/comment',
      DeleteAiToolComment: 'ai-tools/remove/comment',
      AddAiToolLike: 'ai-tools/add/like',
      RemoveAiToolLike: 'ai-tools/remove/like',
      GetChildComments: 'ai-tools/child-comments',
      AddAiToolCommentLike: 'ai-tools/add/comment-like',
      RemoveAiToolCommentLike: 'ai-tools/remove/comment-like',
      GetAiToolLikedUserList: 'ai-tools/user-liked-list',
      GetAiArticleDetails: 'ai-tools/articles/details',
      GetAiArticleComments: 'ai-tools/articles/comments',
      AddAiArticleComment: 'ai-tools/articles/add/comment',
      UpdateAiArticleComment: 'ai-tools/articles/update/comment',
      DeleteAiArticleComment: 'ai-tools/articles/remove/comment',
      AddAiArticleLike: 'ai-tools/articles/add/like',
      RemoveAiArticleLike: 'ai-tools/articles/remove/like',
      GetAiArticleChildComments: 'ai-tools/articles/child-comments',
      AddAiArticleCommentLike: 'ai-tools/articles/add/comment-like',
      RemoveAiArticleCommentLike: 'ai-tools/articles/remove/comment-like',
      GetAiArticleLikedUserList: 'ai-tools/articles/user-liked-list',
      AddAiArticleFavorite: 'ai-tools/articles/add/favorite',
      RemoveAiArticleFavorite: 'ai-tools/articles/remove/favorite',
      GetAIHomePageArticles: 'ai-tools/articles/home-page-articles',
    },
    Dashboard: {
      GetDashboardData: 'dashboard/dashboardData',
      GetCurrentBudget: 'dashboard/currentBudget',
    },
    Worldwide: {
      GetWorldwideCountries: 'worldwide/countries',
      GetWorldwideCountryData: 'worldwide/countryData',
      GetWorldwideFilteredSusSubjects: 'worldwide/filteredSusSubjects',
    },
  },
};
