<div class="apps-container">
  <div class="apps">
    <div class="apps__header">
      <div class="apps__header-text">My Apps</div>
    </div>
    <div class="apps__items">

      <a href="{{item.url}}" target="_blank" *ngFor="let item of apps.slice(0,12); let i = index" class="apps__item-image">
        <img
          [src]="item.icon || '/assets/images/global/playtika-default-image.png'"
          alt="icon"
        />
      </a>

    </div>
    <a (click)="closeDashboardPlease()" routerLink="/apps" class="apps__see-all"> See all </a>
  </div>
</div>
