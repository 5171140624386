import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DashboardService } from '../../Services/DashboardService/DashboardService';

@Component({
  selector: 'app-my-apps',
  templateUrl: './my-apps.component.html',
  styleUrls: ['./my-apps.component.scss']
})
export class MyAppsComponent implements OnInit {

  @Input() apps: any;

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {

  }

  closeDashboardPlease(){
    this.dashboardService.close();
  }
}
