import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { FailedComponent } from './Pages/Failed/Failed.component';
import { MainMenuGuard } from './Core/Guards/main-menu.guard';
import { SideItemsGuard } from './Core/Guards/side-items.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./Pages/Home/home.module').then(md => md.HomeModule),
    canActivate: [MsalGuard],
    data: { title: 'Home' }
  },
  {
    path: 'personal-area',
    loadChildren:() => import('./Pages/Personal-area/personal-area.module').then(m => m.PersonalAreaModule)  ,
    canActivate: [MsalGuard],
    data: { title: 'Personal area' }
  },
  {
    path: 'login-failed',
    component: FailedComponent,
    data: { title: 'Login failed' }
  },
  {
    path: 'apps',
    loadChildren: () => import('./Pages/Apps/apps.module').then(md => md.AppsModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'My apps' }
  },
  {
    path: 'tc',
    loadChildren: () => import('./Pages/TaskCenter/task-center.module').then(md => md.TaskCenterModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'Tickets' }
  },
  {
    path: 'articles',
    loadChildren: () => import('./Pages/Articles/articles.module').then(md => md.ArticlesModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'Articles' }
  },
  {
    path: 'search',
    loadChildren: () => import('./Pages/search-results/search-results.module').then(md => md.SearchResultsModule),
    canActivate: [MsalGuard],
    data: { title: 'Search' }
  },
  {
    path: 'people',
    loadChildren: () => import('./Pages/People/people.module').then(md => md.PeopleModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'People' }
  },
  {
    path: 'wiki',
    loadChildren: () => import('./Pages/Wiki/wiki.module').then(md => md.WikiModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
  },
  {
    path: 'document-libery',
    loadChildren: () => import('./Pages/DocumentLibery/document-libery.module').then(md => md.DocumentLiberyModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
  },
  {
    path: 'notifications',
    loadChildren: () => import('./Pages/Notifications/notifications.module').then(md => md.NotificationsModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'Notifications' }
  },
  {
    path: 'vods',
    loadChildren: () => import('./Pages/Vods/vods.module').then(md => md.VodsModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'Vods' }
  },
  {
    path: 'galleries',
    loadChildren: () => import('./Pages/Gallery/gallery.module').then(md => md.GalleryModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'Galleries' }
  },
  {
    path: 'favorites',
    loadChildren: () => import('./Pages/favorites/favorites.module').then(md => md.FavoritesModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'Favorites' }
  },
  {
    path: 'calendar',
    loadChildren: () => import('./Pages/Calendar/calendar.module').then(md => md.CalendarPageModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'Calendar' }
  },
  {
    path: 'wellbeing',
    loadChildren: () => import('./Pages/Wellbeing/wellbeing.module').then(md => md.WellbeingModule),
    canActivate: [MsalGuard, SideItemsGuard,MainMenuGuard],
    data: { title: 'Wellbeing' }
  },
  {
    path: 'pdf-reader',
    loadChildren: () => import('./Pages/file-reader/file-reader.module').then(md => md.FileReaderModule),
    canActivate: [MsalGuard, SideItemsGuard,MainMenuGuard],
    data: { title: 'PDF Reader' }
  },
  {
    path: 'file-reader',
    loadChildren: () => import('./Pages/file-reader/file-reader.module').then(md => md.FileReaderModule),
    canActivate: [MsalGuard, SideItemsGuard,MainMenuGuard],
    data: { title: 'File Reader' }
  },
  {
    path: 'departments',
    loadChildren: () => import('./Pages/Departments/departments.module').then(md => md.DepartmentsModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'Departments' }
  },
  {
    path: 'not-found',
    loadChildren: () => import('./Pages/not-found/not-found.module').then(md => md.NotFoundModule),
    data: { title: 'Not Found' }
  },
  {
    path: 'worldwide',
    loadChildren: () => import('./Pages/worldwide/worldwide.module').then(md => md.WorldwideModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'Worldwide' }
  },
  {
    path: 'ai-tools',
    loadChildren: () => import('./Pages/AITools/ai-tools.module').then(md => md.AiToolsModule),
    canActivate: [MsalGuard, MainMenuGuard,SideItemsGuard],
    data: { title: 'AI Portal' }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabled' : 'disabled', // Remove this line to use Angular Universal
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
